import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de6405be"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "scrollController",
  class: "app-tabs__head app-tabs__head__scroll_controller"
}
const _hoisted_2 = {
  ref: "scrollContent",
  class: "app-tabs__head__scroll_content d-flex"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "app-tabs__head__button__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SortButton = _resolveComponent("SortButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "app-tabs__head_wrapper",
    style: _normalizeStyle({
      'margin-left': _ctx.margin ? '20px' : '0'
    })
  }, [
    _createVNode(_Transition, { name: "x-left" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["scroll-controller-control scroll-controller-control--left", {
          active: _ctx.showPrevButton
        }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollToHandler('backward')))
        }, [
          _createVNode(_component_SortButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToHandler('backward')))
          })
        ], 2)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "x-right" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["scroll-controller-control", {
          active: _ctx.showNextButton
        }]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.scrollToHandler('forward')))
        }, [
          _createVNode(_component_SortButton, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scrollToHandler('forward')))
          })
        ], 2)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: idx,
            class: _normalizeClass(["app-tabs__head__button", { active: _ctx.modelValue === idx }]),
            onClick: ($event: any) => (_ctx.selectTabHandler(idx))
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(tab), 1)
          ], 10, _hoisted_3))
        }), 128))
      ], 512)
    ], 512)
  ], 4))
}