
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import ShadowControl from "@/components/Form/ShadowControl.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import ManageTemplateModal from "@/components/Templates/ManageTemplateModal.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import HeadToolBar from "@/components/Table/HeadToolBar.vue";

import { mapActions, useStore } from "vuex";
import { templateTableHead } from "@/components/Table/ts/interfaces/tableInstances/templateTable";
import {
  computed,
  defineComponent,
  reactive,
  watch,
  ref,
  nextTick,
  Ref
} from "vue";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import TemplatesTable from "@/components/Table/ts/instances/templates/TemplatesTable.vue";
import {
  IUseBaseAppLayout,
  useBaseAppLayout
} from "@/hooks/layout/useBaseAppLayout";
import { sortOrders } from "@/enums/main/sortOrders";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { api } from "@/api/Api";
import { IUserApplication } from "@/store/interfaces/accounts/IUserAccount";

export default defineComponent({
  name: "Templates",
  components: {
    AppTableController,
    ManageTemplateModal,
    BtnContent,
    PageMainHeading,
    ShadowControl,
    AppButton,
    TemplatesTable,
    HeadToolBar
  },
  setup() {
    const store = useStore();

    const applications: Ref<IUserApplication[]> = ref([]);
    const activeApplicationIdx = ref(0);

    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });

    // Page layout refs
    const layoutRefs: IUseBaseAppLayout = useBaseAppLayout({
      initialSearchQuery: "",
      onFiltersChange: requestTemplatesHandler,
      onChangeSearchQuery() {
        changeViewConfigurationHandler({
          page: 1,
          perPage: viewConfiguration.perPage
        });
      }
    });

    watch(viewConfiguration, requestTemplatesHandler);

    watch(
      () => activeApplicationIdx.value,
      () => {
        requestTemplatesHandler();
        requestTemplateTypes();
      },
      { deep: true }
    );

    const { searchQuery } = layoutRefs;

    const fullFilter = computed(() => {
      return {
        search: searchQuery.value,
        sortField: "subject",
        sortType: sortOrders.asc
      };
    });

    const applicationsTitles = computed(() => {
      return applications.value.map(({ title }) => title);
    });

    const applicationId = computed(() => {
      return applications.value[activeApplicationIdx.value]?.id || -1;
    });

    async function requestApplicationsHandler() {
      await store.dispatch("setAppLoading", true);
      const {
        success,
        message,
        data: apps = []
      } = await api.fetchApplications();

      if (!success && message) {
        return await store.dispatch("showErrorNotification", message);
      }

      applications.value = apps;
      await nextTick(() => {
        requestTemplatesHandler();
        requestTemplateTypes();
      });
    }

    async function requestTemplatesHandler(): Promise<void> {
      await store.dispatch("setAppLoading", true);

      const { success, message } = await store.dispatch("fetchTemplateData", {
        query: { ...viewConfiguration, ...fullFilter.value },
        applicationId: applicationId.value
      });

      if (!success && message) {
        await store.dispatch("showErrorNotification", message);
      }

      await store.dispatch("setAppLoading", false);
    }

    async function requestTemplateTypes(): Promise<void> {
      await store.dispatch("fetchTemplatesTypes", {
        applicationId: applicationId.value
      });
    }

    requestApplicationsHandler();

    const changeTab = (idx: number) => {
      activeApplicationIdx.value = idx;
    };

    function changeViewConfigurationHandler({
      page,
      perPage
    }: // search,
    ITableViewConfiguration): void {
      viewConfiguration.page = page;
      viewConfiguration.perPage = perPage;
    }
    return {
      ...layoutRefs,
      viewConfiguration,
      searchQuery,
      applicationsTitles,
      activeApplicationIdx,
      applicationId,
      templatesList: computed(() => store.getters.templatesList),
      totalTemplatesCount: computed(() => store.getters.totalTemplates),
      requestTemplatesHandler,
      changeViewConfigurationHandler,
      changeTab,
      templatesTypesList: computed(() => store.getters.templatesTypesList)
    };
  },

  data() {
    return {
      tableHead: templateTableHead,
      manageTemplateModalOpened: false,
      editingModalData: {
        id: 0,
        code: "",
        status: "",
        subject: "",
        type: {}
      },
      editTemplateTitle: "",
      modalLoading: false,
      sortConfig: {
        bySubject: { order: sortOrders.turnOff, keyName: "subject" }
      }
    };
  },

  methods: {
    ...mapActions(["setAppLoading", "showErrorNotification"]),
    toggleManageTemplateModal(data: any) {
      this.manageTemplateModalOpened = data.status;
      if (data.data && data.data.id !== 0) {
        this.editingModalData = data.data;
      }
      if (!data.status) {
        this.editingModalData.id = 0;
      }
    },

    async endEditingTemplate(data: any) {
      if (data) {
        await this.saveTemplateHandler(data);
      }
      await this.requestTemplatesHandler();
      this.toggleManageTemplateModal({ status: false });
      this.editingModalData.id = 0;
    },

    async saveTemplateHandler(data: any) {
      this.setAppLoading(true);
      const applicationId = this.applicationId;

      if (this.editingModalData?.id) {
        await this.$store.dispatch("editTemplateData", {
          data: {
            id: Number(this.editingModalData.id),
            code: data.html,
            subject: data.subject,
            status: data.isActive,
            typeId: data.typeId,
            applicationId
          }
        });
      } else {
        await this.$store.dispatch("addTemplate", {
          data: {
            code: data.html,
            subject: data.subject,
            status: data.isActive,
            typeId: data.typeId,
            applicationId
          }
        });
      }
      setTimeout(() => {
        this.setAppLoading(false);
      }, 500);
    },

    async deleteTemplate(id: number) {
      await this.$store.dispatch("deleteTemplate", { id });
      await this.requestTemplatesHandler();
      this.toggleManageTemplateModal(false);
    },

    searchHandler({ target }: Event) {
      if (!target) {
        return;
      }

      this.viewConfiguration.search = (target as HTMLInputElement).value;
    }
  }
});
